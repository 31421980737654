<template>
    <footer>
		<div class="footer__top">
			<div class="custom-container">
				<div class="columns">
					<div class="column is-size-6">
						<div
                            class="left"
                            v-if="locale == 'pl'"
                        >
                            <div class="comapny-name f600">Operator akademików Living and Studying - biuro</div>
                            <div class="comapny-adres">Rynek 7, 50-106 Wrocław</div>
                            
                            <div class="comapny-mail">

                            email: hello@las.com.pl<br/>

                            <a
                                :href="'/polityka-prywatnosci'"
                                @click.prevent="navigateTo('/polityka-prywatnosci')">Polityka prywatności</a><br/>

                            <a
                                :href="'/regulamin-systemu'"
                                @click.prevent="navigateTo('/regulamin-systemu')">Regulamin systemu</a><br/>

							<a
                                :href="'/obowiazek-informacyjny'"
                                @click.prevent="navigateTo('/obowiazek-informacyjny')">Obowiązek informacyjny</a><br/>

                            </div>
                        </div>
                        <div
                            class="left"
                            v-else-if="locale == 'en'"
                        >
                            <div class="comapny-name f600">Management of private student houses Living and Studying - office</div>
                            <div class="comapny-adres">Rynek 7, 50-106 Wrocław</div>
                            
                            <div class="comapny-mail">

                            email: hello@las.com.pl<br/>

                            <a
                                :href="'/privacy-policy'"
                                @click.prevent="navigateTo('/privacy-policy')">Privacy policy</a><br/>

                            <a
                                :href="'/system-regulations'"
                                @click.prevent="navigateTo('/system-regulations')">System regulations</a><br/>

							<a
                                :href="'/information-clause'"
                                @click.prevent="navigateTo('/information-clause')">Information clause</a><br/>

                            </div>
                        </div>
                        <div
                            class="left"
                            v-else-if="locale == 'ua'"
                        >
                            <div class="comapny-name f600">Operator akademików Living and Studying - biuro</div>
                            <div class="comapny-adres">Rynek 7, 50-106 Wrocław</div>
                            
                            <div class="comapny-mail">

                            email: hello@las.com.pl<br/>

                            <a
                                :href="'/privacy-policy'"
                                @click.prevent="navigateTo('/privacy-policy')">Privacy policy</a><br/>

                            <a
                                :href="'/system-regulations'"
                                @click.prevent="navigateTo('/system-regulations')">System regulations</a><br/>
							
							<a
                                :href="'/information-clause'"
                                @click.prevent="navigateTo('/information-clause')">Information clause</a><br/>

                            </div>
                        </div>
					</div>
					<div class="column is-size-6">
						<div class="right">
							<div
                                v-if="locale == 'pl'"
                                v-html="footerRightSide"
                            ></div>
							<div
                                v-else-if="locale == 'en'"
                            >
                                Modern dorm<br/>
                                with micro apartments<br/>
                                in Wroclaw</div>
							<div
                                v-else-if="locale == 'ua'"
                            >
                                Сучасні гуртожитки<br/>
                                з Мікроапартаментами<br/>
                                у Вроцлаві
                            </div>
							<img class="logo" :src="footerLogo" alt="LAS Wrocław">
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="footer__bot">
			<div
                class="custom-container is-size-7 footer__margin"
                v-html="footerBottomContent"
                v-if="locale == 'pl'"
            ></div>
            <div
                class="custom-container is-size-7 footer__margin"
                v-else-if="locale == 'ua'"
            >Візуалізації, анімації та моделі побудови, представлені на сайті LAS, мають ілюстративний характер. Всі права захищені. Права на використання, копіювання та розповсюдження всіх даних і матеріалів, розміщених на цьому веб-сайті, підлягають, зокрема, положенням Закону від 4 лютого 1994 року про авторське право і суміжні права (Журнал законів 2006 № 90, пункт 631, зі змінами). Використання даних або матеріалів з цього сайту для будь-яких цілей вимагає у кожному випадку письмового дозволу.</div>
            <div
                class="custom-container is-size-7 footer__margin"
                v-else-if="locale == 'en'"
                v-html="footerBottomContent"
            ></div>
		</div>
    </footer>
</template>

<script>
export default {
	computed: {
		footerLogo(){
			return this.$store.getters.getFooterLogo.url
		},
		footerLeftSide(){
			return this.$store.getters.getFooterLeftSide
		},
		footerRightSide(){
			return this.$store.getters.getFooterRightSide
		},
		footerBottomContent(){
			return this.$store.getters.getFooterBottomContent
		},
        locale(){
            return this.$store.getters.getLocale
        },
	},
    methods: {
        navigateTo(url){
            const publicPath = '/' + this.$store.getters.getPublicPath
            const locale = this.$store.getters.getLocale
            if(url != '#'){
                this.$router.push(url)
                this.burger = false
            }
        },
    }
}
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";

footer{
    a{
        color: white;
        &:hover{
            text-decoration: underline;
        }
    }
	margin-top: 200px;
	.footer__top{
		background-color: black;
		padding: 50px 10px;
		color: #fff;
		.left{
			text-align: left;
			@include las-touch{
				text-align: center;
			}
		}
		.right{
			text-align: right;
			@include las-touch{
				text-align: center;
			}
			.logo{
				margin-top: 20px;
                max-width:330px;
			}
		}
	}
	.footer__bot{
		background-color: #2d2d2d;
		padding: 50px 10px;
		color: #fff;
	}
	.footer__margin{
		@include las-touch{
			padding: 0 40px;
		}
	}
}
</style>
