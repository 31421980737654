<template>
    <header
        class="header"
        :class="{ burgerActive: isBurgerActive, scrollActive: isScrollActive }"
    >

        <!-- Sticky nav and rwd nav -->
        <div class="header__container header__container--scroll" id="header-container-scroll">
            <nav class="header__nav header__nav--scroll">
                <div
                    class="header__nav-item header__nav-item--scroll"
                    @click="activeSubmenu = (activeSubmenu == menuKey) ? 0 : menuKey"
                    v-for="(menuItem, menuKey) in mainMenu"
                    :key="menuKey"
                >
                    <a
						class="header__nav-href header__nav-href--scroll is-size-7"
						v-if="!menuItem.url.includes('http')"
                        :href="'/'+menuLocale+menuItem.url"
                        @click.prevent="menuItem.children ? '' : navigateTo('/'+menuItem.url)"
                        v-html="menuItem.title"
                    ></a>
					<a
						v-else
						class="header__nav-href header__nav-href--scroll is-size-7"
						:href="menuItem.url"
						v-html="menuItem.title"
					></a>
                </div>
                <div
                    class="header__nav-item"
                >
                <!--
                    <a
                        href="https://booking.las.com.pl/pl"
                        class="header__nav-href header__nav-href--scroll is-size-7"
                        v-if="locale == 'pl'"
                    >Zaloguj</a>
                    <a
                        href="https://booking.las.com.pl/en"
                        class="header__nav-href header__nav-href--scroll is-size-7"
                        v-else-if="locale == 'en'"
                    >Login</a>
                    <a
                        href="https://booking.las.com.pl/en"
                        class="header__nav-href header__nav-href--scroll is-size-7"
                        v-else
                    >Login</a>
                    -->
                </div>
	        </nav>
		</div>

        <!-- 1st row of menu (price list btn) -->
		<div class="header__container">

            <div @click="burgerHandler" class="header__burger">
                <span class="header__burger-item"></span>
                <span class="header__burger-item"></span>
                <span class="header__burger-item"></span>
            </div>

            <div class="header__top-bar header__top-bar--display">
            <!--
				<a
					href="https://booking.las.com.pl/pl"
					v-if="locale == 'pl'"
                    rel="nofollow"
					class="header__price-list-btn header__price-list-btn--yellow">Zaloguj</a>
				<a
					href="https://booking.las.com.pl/en"
					v-else-if="locale == 'en'"
                    rel="nofollow"
					class="header__price-list-btn header__price-list-btn--yellow">Login</a>
				<a
					href="https://booking.las.com.pl/en"
					v-else
                    rel="nofollow"
					class="header__price-list-btn header__price-list-btn--yellow">Login</a>
                    -->
			</div>
			<div class="header__top-bar">
            <!--
				<a
					href="https://booking.las.com.pl/pl/booking"
					v-if="locale == 'pl'"
                    rel="nofollow"
					class="header__price-list-btn">Cennik</a>
				<a
					href="https://booking.las.com.pl/en/booking"
					v-else-if="locale == 'en'"
                    rel="nofollow"
					class="header__price-list-btn">Prices</a>
				<a
					href="https://booking.las.com.pl/en/booking"
					v-else
                    rel="nofollow"
					class="header__price-list-btn">ЦІНИ</a>
                    -->
			</div>
 
            <div class="header__control-container header__control-container--mobile">
                <div class="header__lang-container">
                    <!-- Now is hard coded but in the nearest future we will change it-->
                    <span
						v-for="lang in languages" v-if="lang.lang!='ua'" 
					>
						<a 
							:href="langHomeUrl(lang.home_url)"
							:class="[ lang.lang == locale ? 'bold' : '' ]"
						>{{ lang.lang }}</a>/
					</span>
                </div>
                <div class="header__social-media-container">

                    <a href="https://www.instagram.com/las_akademik/" target="_blank">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 27 27" style="enable-background:new 0 0 27 27;" xml:space="preserve">
                            <g>
                            <path d="M13.6,9.4c-2.2,0-4.1,1.8-4.1,4.1s1.8,4.1,4.1,4.1c2.2,0,4.1-1.8,4.1-4.1S15.9,9.4,13.6,9.4 M13.6,20
                                c-3.6,0-6.5-2.9-6.5-6.5S10,7,13.6,7c3.6,0,6.5,2.9,6.5,6.5S17.2,20,13.6,20"/>
                            <path d="M21.8,6.7c0,0.8-0.7,1.5-1.5,1.5c-0.8,0-1.5-0.7-1.5-1.5c0-0.8,0.7-1.5,1.5-1.5C21.1,5.2,21.8,5.9,21.8,6.7"/>
                            <path d="M23.2,8c0-2.3-1.9-4.2-4.2-4.2H8C5.7,3.8,3.8,5.7,3.8,8V19c0,2.3,1.9,4.2,4.2,4.2H19c2.3,0,4.2-1.9,4.2-4.2V8z M25.6,18.9
                                c0,3.7-3,6.7-6.7,6.7H8.1c-3.7,0-6.7-3-6.7-6.7V8.1c0-3.7,3-6.7,6.7-6.7h10.9c3.7,0,6.7,3,6.7,6.7V18.9z"/>
                            </g>
                        </svg>
                    </a>

                    <a href="https://www.facebook.com/LASakademik/" target="_blank">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 27 27" style="enable-background:new 0 0 27 27;" xml:space="preserve">
                            <g>
                            <path d="M25.6,5.4c0-2.1-1.9-4-4-4H5.4c-2.1,0-4,1.9-4,4v16.1c0,2.1,1.9,4,4,4h8.1v-9.1h-3v-4h3v-1.6c0-2.7,2-5.2,4.5-5.2h3.3v4H18
                                c-0.4,0-0.8,0.4-0.8,1.1v1.6h4v4h-4v9.1h4.3c2.1,0,4-1.9,4-4V5.4z"/>
                            </g>
                        </svg>
                    </a>
                </div>
            </div>
		</div>

        <!-- Second row of menu, with logo and lang options-->
		<div class="header__container">

			<div class="header__logo">
				<a href="/" @click.prevent="$router.push('/')">
					<img :src="headerLogo" alt="LAS – Nowoczesny akademik z mikroapartamentami">
				</a>
			</div>

            <div class="header__control-container">
                <div class="header__lang-container">
                    <span
						v-for="lang in languages" v-if="lang.lang!='ua'"
					>
						<a
							:href="langHomeUrl(lang.home_url)"
							:class="[ lang.lang == locale ? 'bold' : '' ]"
						>{{ lang.lang }}</a>/
					</span>
                </div>
                <div class="header__social-media-container">
                    <a href="https://www.instagram.com/las_akademik/" target="_blank">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 27 27" style="enable-background:new 0 0 27 27;" xml:space="preserve">
                            <g>
                            <path d="M13.6,9.4c-2.2,0-4.1,1.8-4.1,4.1s1.8,4.1,4.1,4.1c2.2,0,4.1-1.8,4.1-4.1S15.9,9.4,13.6,9.4 M13.6,20
                                    c-3.6,0-6.5-2.9-6.5-6.5S10,7,13.6,7c3.6,0,6.5,2.9,6.5,6.5S17.2,20,13.6,20"/>
                            <path d="M21.8,6.7c0,0.8-0.7,1.5-1.5,1.5c-0.8,0-1.5-0.7-1.5-1.5c0-0.8,0.7-1.5,1.5-1.5C21.1,5.2,21.8,5.9,21.8,6.7"/>
                            <path d="M23.2,8c0-2.3-1.9-4.2-4.2-4.2H8C5.7,3.8,3.8,5.7,3.8,8V19c0,2.3,1.9,4.2,4.2,4.2H19c2.3,0,4.2-1.9,4.2-4.2V8z M25.6,18.9
                                c0,3.7-3,6.7-6.7,6.7H8.1c-3.7,0-6.7-3-6.7-6.7V8.1c0-3.7,3-6.7,6.7-6.7h10.9c3.7,0,6.7,3,6.7,6.7V18.9z"/>
                            </g>
                        </svg>
                    </a>
                    <a href="https://www.facebook.com/LASakademik/" target="_blank">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 27 27" style="enable-background:new 0 0 27 27;" xml:space="preserve">
                            <g>
                            <path d="M25.6,5.4c0-2.1-1.9-4-4-4H5.4c-2.1,0-4,1.9-4,4v16.1c0,2.1,1.9,4,4,4h8.1v-9.1h-3v-4h3v-1.6c0-2.7,2-5.2,4.5-5.2h3.3v4H18
                                c-0.4,0-0.8,0.4-0.8,1.1v1.6h4v4h-4v9.1h4.3c2.1,0,4-1.9,4-4V5.4z"/>
                            </g>
                        </svg>
                    </a>
                </div>
            </div>

		</div>

        <!-- Desktop menu, hide on mobile -->
		<div class="header__container">
	        <nav class="header__nav header__nav--main">
                <div
                    class="header__nav-item"
                    @click="activeSubmenu = (activeSubmenu == menuKey) ? 0 : menuKey"
                    v-for="(menuItem, menuKey) in mainMenu"
                    :key="menuKey"
                >
                    <a
						class="header__nav-href is-size-7"
						v-if="!menuItem.url.includes('http')"
                        :href="'/'+menuLocale+menuItem.url"
                        @click.prevent="menuItem.children ? '' : navigateTo('/'+menuItem.url)"
                        v-html="menuItem.title"
                    ></a>
					<a
						v-else
						class="header__nav-href is-size-7"
						:href="menuItem.url"
						v-html="menuItem.title"
					></a>
                </div>
	        </nav>
		</div>


    </header>
</template>

<script>
export default {
    data() {
        return {
            burger: false,
            menuLang: false,
            activeSubmenu: 0,
            isBurgerActive: false,
            isScrollActive: false
        }
    },
    watch: {
		'$route': 'menuOff'
	},
    methods: {
        langHomeUrl(home_url) {
            return this.publicPath + home_url
        },
        menuOff(){
            this.isBurgerActive = false;
        },
        navigateTo(url){
            const publicPath = '/' + this.$store.getters.getPublicPath
            const locale = this.$store.getters.getLocale
            if(url != '#'){
             this.$router.push(url)
             this.burger = false
            }
        },
        burgerHandler() {
            this.isBurgerActive = !this.isBurgerActive;
        },
        scrollHandler() {
            const offsetY = window.scrollY,
                screenWidth = window.innerWidth;

            if ((offsetY > 240) && (screenWidth > 991)) {
                this.isScrollActive = true;
            } else {
                this.isScrollActive = false;
            }
        },
        resizeHandler() {
            const width = window.innerWidth;

            if (width > 991) {
               this.isBurgerActive = false;
            }
        }
    },
    computed: {
        languages(){
            return this.$store.getters.getLanguages
        },
        options(){
            return this.$store.getters.getOptions
        },
        locale(){
            return this.$store.getters.getLocale
        },
        menuLocale(){
            return (this.locale != this.$store.getters.getOptions.default_language) ? this.locale+'/' : ''
        },
        publicPath(){
            return this.$store.getters.getPublicPath
        },
        mainMenu(){
            return this.$store.getters.getMainMenu
        },
		headerLogo(){
			return this.$store.getters.getHeaderLogo.url
		}
    },
    created() {
       window.addEventListener("scroll", this.scrollHandler);
       window.addEventListener("resize", this.resizeHandler);
    }
}
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";

.header {
    &__container,
    &__top-bar,
    &__nav,
    &__control-container,
    &__lang-container,
    &__social-media-container {
        display: flex;
    }

    &__container {
        max-width: 1170px;
        width: 100%;
        margin: 0 auto;

        @include las-small-screen {
            width: 970px;
        }

        @include las-touch {
            width: 100%;
        }

        &--scroll {
            transform: translateY(-60px);
            transition: transform .5s ease-in-out, opacity .5s ease-in-out;
            position: fixed;
            max-width: 2500px;
            width: 100%;
            opacity: 0;
            top: 0;
            background-color: #000;

            @include las-touch {
                transform: translateY(-350px);
                display: flex;
                z-index: 100;
                opacity: 1;
                transition: transform .35s ease-in-out;
            }
        }

        &:first-child {
            z-index: 20;
            justify-content: center;
        }

        &:nth-child(2) {
            justify-content: flex-end;
            z-index: 20;

            @include las-touch {
                margin-bottom: 35px;
                background-color: #ddd;
                position: fixed;
            }
        }

        &:nth-child(3) {
            justify-content: space-between;

            @include las-touch {
                justify-content: center;
                padding-top: 120px;
            }
        }

        &:last-child {
            @include las-touch {
                display: none;
            }
        }
    }

    &__burger {
        background-color: #000;
        padding: 15px;
        z-index: 10;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: 51.5px;
        display: none;

        @include las-touch {
            display: flex;
        }
    }

    &__burger-item {
        width: 25px;
        height: 4px;
        position: relative;
        background-color: $white;
        border-radius: 1px;
        transform-origin: 4px 0px;
        display: block;

        &:not(:last-child) {
            margin-bottom: 4px;
        }
    }

    &__top-bar {
        justify-content: flex-end;
        @include las-touch {
            width: 100%;
        }
        &--display{
            @include las-touch{
                display: none;
            }
        }
    }

    &__price-list-btn {
        background-color: $orange;
		padding: 15px 60px;
		color: black;
		display: block;
        text-transform: uppercase;
        font-weight: bold;
        &--yellow{
            background-color: $yellow;
        }

        @include las-touch {
            flex-grow: 3;
            text-align: center;
        }

        @include las-phone {
            padding: 15px 10px;
        }
    }

    &__logo {
        max-width: 336px;
		margin-bottom: 45px;
        margin-top:35px;
    }

    &__control-container {
        @include las-touch {
            display: none;
        }

        &--mobile {
            display: none;

            @include las-touch {
                display: flex;
                margin: 0;
                padding: 0 20px;
                align-items: center;
            }
        }
    }

    &__lang-container,
    &__social-media-container {
        height: 35px;
        align-items: center;
        margin-top: 1.8rem;

        @include las-touch {
            margin-top: 0rem;
        }
    }

    &__lang-container {
        & span a {
            color: black;
			text-transform: uppercase;
        }
		.bold{
			font-weight: 900;
		}
    }

    &__social-media-container {
        & svg {
            width: 20px;
            margin: 0 10px;
            padding-top: 7px;
            cursor: pointer;

            @include las-phone {
                width: 15px;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__nav {
        width: 100%;
		background-color: $menucolor;
        justify-content: space-around;
        max-width: 1170px;

        @include las-touch {
            flex-direction: column;
            display: none;
        }

        &--scroll {
            justify-content: center;
            width: auto;
            // transition: transform .6s ease-in-out;
            width: 100%;
            // max-width: 1170px;

            @include las-touch {
                transform: translateY(-350px);
                display: flex;
                position: fixed;
                z-index: 100;
                width: 100%;
            }
        }
    }

    &__nav-item {
        transition: background-color .15s ease-in-out;
        flex-grow: 1;
        text-align: center;

        &:nth-child(3) {
            background-color: $yellow;
			color: black;
        }

        &:nth-child(2):hover {
            background-color: $white;
        }

        &:nth-child(3):hover,
        &:nth-child(7):hover,
        &:nth-child(10):hover,
        &:nth-child(1):hover {
            background-color: $yellow;
        }

        &:nth-child(4):hover,
        &:nth-child(6):hover,
        &:nth-child(9):hover {
            background-color: $blue;
        }

        &:nth-child(5):hover,
        &:nth-child(8):hover {
            background-color: $orange;
        }
        &:last-child:hover {
            background-color: $orange;
        }
    }

    &__nav-href {
        color: $white;
		text-transform: uppercase;
		padding: 15px 18px;
		display: block;

        &--scroll {
            text-align: center;
            //padding: 15px 23px;
        }

        @include las-small-screen {
            padding: 15px 10px;
        }

        @include las-touch {
            padding: 10px;
            font-size: 1rem !important;
            text-align: left;
        }

		&:hover {
            color: black;
        }
    }
}

// Modifiers
.header.burgerActive .header__nav--scroll {
    transform: translateY(404px);
    opacity: 1;
}

.header.scrollActive .header {

    &__container--scroll {
        max-width: 2500px;
        width: 100%;
        opacity: 1;
        transform: translateY(0px);
        position: fixed;
        top: 0;
        background-color: #000;
        justify-content: center;
    }

    &__nav-href {
        padding: 15px 23px;

        @include las-small-screen {
            padding: 15px;
        }
    }
}

</style>
